@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Poppins';
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* background effects */
.section-1::before {
  content: "";
  position: absolute;
  top: -250px;
  left: -250px;
  width: 30%;
  height: 60%;
  background-color: #D4AF37;
  border-radius: 50%;
  z-index: -1;
}

.section-1-middle::before {
  content: "";
  position: absolute;
  bottom: -350px;
  right: -350px;
  width: 30%;
  height: 60%;
  background-color: #D4AF37;
  border-radius: 50%;
  z-index: -2;
  transform: scale(0.7);
}

.section-1-middle::after {
  content: "";
  position: absolute;
  bottom: -310px;
  right: -400px;
  width: 30%;
  height: 50%;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0.7);
}

.section-2-middle::before {
  content: "";
  position: absolute;
  bottom: -350px;
  left: -350px;
  width: 30%;
  height: 60%;
  background-color: #D4AF37;
  border-radius: 50%;
  z-index: -2;
  transform: scale(0.7);
}

.section-2-middle::after {
  content: "";
  position: absolute;
  bottom: -310px;
  left: -400px;
  width: 30%;
  height: 50%;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0.7);
}

.section-3-middle::before {
  content: "";
  position: absolute;
  bottom: -350px;
  right: -350px;
  width: 30%;
  height: 60%;
  background-color: #D4AF37;
  border-radius: 50%;
  z-index: -2;
  transform: scale(0.7);
}

.section-4-middle::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -350px;
  width: 30%;
  height: 65%;
  background-color: #D4AF37;
  border-radius: 50%;
  z-index: -2;
  transform: translate(0%, -25%) scale(0.7);
}
